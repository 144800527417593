import { type HTMLAttributes } from "react";
import Dinero from "dinero.js";
import { twMerge } from "tailwind-merge";

export function Currency({
  className,
  ...props
}: HTMLAttributes<HTMLSpanElement> & {
  amount: number | string;
  currency: string;
  format?: "$0,0.00" | "$0,0" | "$0" | "$0.0";
}) {
  const format = props.format ?? "$0,0.00";
  return (
    <span className={twMerge(className)} {...props}>
      {Dinero({
        amount: parseInt(`${props.amount}`),
        // @ts-ignore
        currency: props.currency,
      }).toFormat(format)}
    </span>
  );
}
